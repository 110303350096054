import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  AccountBox,
  Close,
  Login,
  Logout,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Box, Button, Link, useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState } from "react";

interface IMenu {
  dashboardHref: string;
  iconColor?: string;
}

export default function Menu({
  dashboardHref,
  iconColor = "primary.main",
}: IMenu) {
  const { session, signout } = useAuth();

  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const ref = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box>
      <Button
        onClick={handleMenuToggle}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          ...(isMobile
            ? {
                display: "flex",
                width: "100%",
              }
            : {
                display: "none",
              }),
        }}
      >
        {isMobileMenuOpen ? (
          <Close
            sx={{
              color: iconColor,
            }}
          />
        ) : (
          <MenuIcon
            sx={{
              color: iconColor,
            }}
          />
        )}
      </Button>

      <>
        <Box
          onClick={handleMenuToggle}
          sx={{
            ...(isMobile
              ? {
                  zIndex: 99,
                  position: "fixed",
                  height: "100vh",
                  width: "100vw",
                  left: 0,
                  top: 0,
                  background: "#00000014",
                  display: isMobileMenuOpen ? "block" : "none",
                }
              : {}),
          }}
        />
        <Box
          ref={ref}
          sx={{
            display: "flex",
            gap: 2,
            ...(isMobile
              ? {
                  zIndex: 100,
                  p: 4,
                  width: 260,
                  position: "fixed",
                  height: "100vh",
                  left: 0,
                  top: 0,
                  flexDirection: "column",
                  transform: isMobileMenuOpen
                    ? "translateX(0)"
                    : "translateX(-100%)",
                  transition: "transform 0.3s ease-in-out",
                  backgroundColor: "white",
                }
              : {}),
          }}
        >
          <Link
            href="/contact"
            target="_blank"
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            <Button variant="outlined" sx={{ width: "100%" }}>
              {t("header-contact")}
            </Button>
          </Link>
          {session ? (
            <>
              <Button
                variant="contained"
                disableElevation
                startIcon={<AccountBox />}
                component="a"
                href={dashboardHref}
                sx={{ width: isMobile ? "100%" : "auto" }}
              >
                {t("header-account")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                startIcon={<Logout />}
                onClick={signout}
                sx={{ width: isMobile ? "100%" : "auto" }}
              >
                {t("header-logout")}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              disableElevation
              startIcon={<Login />}
              component="a"
              href="/login"
              sx={{ width: isMobile ? "100%" : "auto" }}
            >
              {t("header-login")}
            </Button>
          )}
        </Box>
      </>
    </Box>
  );
}
