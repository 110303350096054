import { SxProps } from "@mui/system";
import React, { createContext } from "react";

type CustomizationContextType = {
  logo: {
    src: string;
    sx?: SxProps;
    textOnly?: boolean;
  };
  languages: LanguageSelectOptions;
};

export const CustomizationContext = createContext<
  CustomizationContextType | undefined
>(undefined);

export default function CustomizationProvider({
  children,
  value,
}: {
  value?: CustomizationContextType;
  children: React.ReactNode;
}) {
  return (
    <CustomizationContext.Provider value={value}>
      {children}
    </CustomizationContext.Provider>
  );
}
