import useCompany from "@cospex/client/hooks/useCompany";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

const CompanyInfo = ({
  color = "black",
  textAlign = "center",
  width = 300,
}: {
  color?: string;
  textAlign?: "center" | "left" | "right";
  width?: number;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const companyData = useCompany();
  const { name, address, country, registration_number } = companyData;
  const capitalizedCountry = country.charAt(0).toUpperCase() + country.slice(1);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const context = canvas.getContext("2d")!;

    const fontSize = 14;
    const lineHeight = fontSize * 1.7;
    const text = `${name} | ${address}, ${capitalizedCountry} : ${registration_number}`;

    const wrapTextIntoLines = (
      ctx: CanvasRenderingContext2D,
      text: string,
      maxWidth: number
    ) => {
      const words = text.split(" ");
      let line = "";
      const lines: string[] = [];

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && n > 0) {
          lines.push(line.trim());
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }
      if (line) {
        lines.push(line.trim());
      }

      return lines;
    };

    context.font = `${fontSize}px system-ui, sans-serif`;

    const linesArray = wrapTextIntoLines(context, text, width);
    const height = linesArray.length * lineHeight;

    const ratio = window.devicePixelRatio || 1;
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    context.scale(ratio, ratio);

    context.clearRect(0, 0, width, height);

    context.font = `${fontSize}px system-ui, sans-serif`;
    context.fillStyle = color;

    const x = 0;
    let y = fontSize;

    linesArray.forEach((line) => {
      const lineWidth = context.measureText(line).width;
      let xPos = x;
      if (textAlign === "center") {
        xPos = (width - lineWidth) / 2;
      } else if (textAlign === "right") {
        xPos = width - lineWidth - x;
      }
      context.fillText(line, xPos, y);
      y += lineHeight;
    });
  }, [color, textAlign, width]);

  return <Box component="canvas" ref={canvasRef} />;
};

export default CompanyInfo;
