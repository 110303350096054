import { useCustomTranslation } from "@cospex/client/context/CustomTranslationProvider";
import { getPrices } from "@cospex/client/hooks/usePrices";
import { Box, Button, Stack, TextareaAutosize, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation as originalUseTranslation } from "react-i18next";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  () => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 4px;
    color: ${grey[900]};
    background: ${"#fff"};
    border: 1px solid ${grey[200]};
    box-shadow: 0px 2px 2px ${grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const TranslationEdit = ({ t, tkey }: { t: any; tkey: string }) => {
  const { customTranslation, updateTranslation } = useCustomTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const editRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);

  const initialTranslationValue = customTranslation?.[tkey] || t(tkey);

  const [originalValue, setOriginalValue] = useState(initialTranslationValue);

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setOriginalValue(customTranslation?.[tkey] || t(tkey));
  }, [customTranslation?.[tkey]]);

  const getTopLeftValues = () => {
    const rect = ref.current?.getBoundingClientRect();
    let top = rect?.bottom || 0;
    let left = rect?.left || 0;

    // Ensure the box is never outside the screen
    if (window.innerWidth < left + 350) {
      left = window.innerWidth - 350;
    }
    if (window.innerHeight < top + 200) {
      top = window.innerHeight - 200;
    }

    return { top: `${top}px`, left: `${left}px` };
  };

  const save = async () => {
    try {
      const value = textRef.current!.value;
      updateTranslation(tkey, value);
      setEditing(false);
      setOriginalValue(value);
    } catch (e) {
      setError(true);
      setErrorMessage((e as any).message);
    }
  };

  const cancel = () => {
    setEditing(false);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEditing(true);
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    save();
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    cancel();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      save();
    }
  };

  const translationEditStyle: React.CSSProperties = {
    display: "inline-block",
    position: "relative",
    borderColor: customTranslation?.[tkey] ? "lightgreen" : "red",
    borderStyle: "solid",
    borderWidth: "3px",
    borderRadius: "3px",
    padding: "2px",
    cursor: "pointer",
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editRef.current && !editRef.current.contains(event.target as Node)) {
        setEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    const handleScroll = () => {
      if (ref.current && editRef.current) {
        const rect = ref.current.getBoundingClientRect();
        let top = rect.bottom;
        let left = rect.left;

        // Ensure the box is never outside the screen
        if (window.innerWidth < left + 350) {
          left = window.innerWidth - 350;
        }
        if (window.innerHeight < top + 200) {
          top = window.innerHeight - 200;
        }

        editRef.current.style.top = `${top}px`;
        editRef.current.style.left = `${left}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (editing) {
      setTimeout(() => {
        textRef.current!.focus();
        textRef.current!.setSelectionRange(0, textRef.current!.value.length);
      }, 10);
    }
  }, [editing]);

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <div ref={ref} style={translationEditStyle} onClick={handleClick}>
        {originalValue}
      </div>
      {editing &&
        createPortal(
          <Box
            ref={editRef}
            style={{
              padding: "1rem",
              ...getTopLeftValues(),
              background: "#fff",
              boxShadow:
                "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)",
              border: "1px solid #ccc",
              borderRadius: "3px",
              minWidth: 350,
              zIndex: 999,
              position: "fixed",
            }}
          >
            <StyledTextarea
              ref={textRef}
              defaultValue={originalValue}
              onKeyDown={onKeyDown}
            />
            <Stack direction="row" gap={1} mt={1} justifyContent="flex-end">
              <Button onClick={handleCancel} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleSave} variant="contained">
                Save
              </Button>

              {error && <span>Error: {errorMessage}</span>}
            </Stack>
          </Box>,
          document.body
        )}
    </div>
  );
};

export default function useTranslation(
  ...args: Parameters<typeof originalUseTranslation>
) {
  const { translationActive } = useCustomTranslation();
  const params = originalUseTranslation(...args);
  const prices = getPrices(params.i18n.language);

  const originalTranslationFn = params.t;

  params.t = function (...args: Parameters<typeof params.t>) {
    const res = originalTranslationFn(...args);

    // Monkey patch t function to interpolate pricing information into
    // dictionary text at relevant placeholders -
    // REBILL_PRICE and SUBSCRIPTION_PRICE
    return prices.interpolate(res);
  } as typeof params.t;

  return translationActive
    ? ({
        ...params,
        t: (key: string) => (
          <TranslationEdit tkey={key} t={originalTranslationFn} />
        ),
      } as unknown as typeof params)
    : params;
}
